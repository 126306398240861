

body {
  background: white;
  font-family: 'Nunito', sans-serif;
}

@media only screen and (max-width: 360px) and (max-height: 640px) {
  .bg {
    min-height: 200px;
  }

  .title-text {
    font-size: calc(36px + 6vw);
    color: white;
  }
  .subtitle-text {
    color: white;
    font-size: calc(16px + 3vw);
  }
}